import NPSModal from 'kuzco/lib/NPSModal';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxState } from '../AppLoader/types';
import events from '../Events/Events';
import { createNpsSurveyRatingSubmittedAction } from './NPSModalLoaderActions';
import {
  selectNPSModalSurveyId,
  selectNPSModalContext,
  selectNPSModalResponsePromise,
} from './NPSModalLoaderSelectors';

const NPSModalLoaderContainer = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state: ReduxState) => state.auth?.user?.id);
  const surveyId = useSelector(selectNPSModalSurveyId);
  const context = useSelector(selectNPSModalContext);
  const responsePromise = useSelector(selectNPSModalResponsePromise);

  useEffect(() => {
    events.telemetry.increment('nps.modal.rendered');
  }, []);

  return (
    <NPSModal
      mainQuestion="How likely are you to recommend Storyblocks to a friend or colleague?"
      promoterFollowUpQuestion="We're so happy you're happy! Can you please tell us more about your experience?"
      neutralFollowUpQuestion="Thanks for your feedback! Can you please tell us more about your experience?"
      detractorFollowUpQuestion="Thanks for your feedback. What could we do to improve your experience?"
      maxScore={10}
      detractorUpperBound={6}
      promoterLowerBound={9}
      onScoreClick={(score) => {
        const surveyRoute =
          '/' +
          __CURRENT_SITE__ +
          '/survey/' +
          surveyId +
          '/' +
          userId +
          '/' +
          score +
          '?context=' +
          context +
          '&ajax=1';
        const submitPromise = fetch(
          new Request(surveyRoute, { method: 'GET' })
        );
        dispatch(createNpsSurveyRatingSubmittedAction(submitPromise));
        events.telemetry.gauge('nps.modal.scored', score);
      }}
      onCommentSubmit={(comment) => {
        events.telemetry.increment('nps.modal.commented');

        responsePromise.then((response) => {
          if (!response.ok) {
            return;
          }

          response.json().then((responseResolved) => {
            const form = new URLSearchParams();
            form.append('response_id', responseResolved['responseId']);
            form.append('comment', comment);

            fetch('/api/survey', {
              body: form,
              method: 'POST',
              headers: {
                'Content-Type':
                  'application/x-www-form-urlencoded; charset=UTF-8',
              },
            });
          });
        });
      }}
      overlayClassName="z-1000"
      modalClassName="w-screen md:w-auto"
      modalClassPrefix="nps"
    />
  );
};

export default NPSModalLoaderContainer;
