import '../common/javascripts/webpack.public.path';
import '../common/javascripts/shared/toggleExpand.js';
import '../common/javascripts/thirdparty/sweetalert.min.js';

import '../common/stylesheets/external-crutch.less';
import '../common/stylesheets/thirdparty/sweetalert.css';
import '../global/elements/index.less';
import '../global/settings/index.less';
import '../global/vendor/bootstrap/bootstrap-xl.css';
import '../global/vendor/tailwind/tailwind.less';

// Header
import '../common/stylesheets/modules/header/index.less';
import '../common/stylesheets/modules/widgets/topCallToAction.less';

// Footer
import '../common/stylesheets/modules/footer/footer.less';
import '../common/stylesheets/modules/seo/footer-description.less';
