import { ReduxState } from '../AppLoader/types';

export const selectNPSModalSurveyId = (state: ReduxState) =>
  state.npsModal.surveyId;

export const selectNPSModalContext = (state: ReduxState) =>
  state.npsModal.context;

export const selectNPSModalResponsePromise = (state: ReduxState) =>
  state.npsModal.responsePromise;
