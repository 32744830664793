import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalDialog from '@videoblocks/shared-components/dist/mjs/components/Shared/ModalDialog';

import { hidePopUp } from '../actions/PopUpsActions';
import {
  selectPopUpIsOpen,
  selectPopUpsContext,
} from '../selectors/PopUpsSelectors';
import { CONFIRM_ACTION_MODAL } from './types';

const ConfirmActionModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectPopUpIsOpen(CONFIRM_ACTION_MODAL));
  const context = useSelector(selectPopUpsContext);

  const handleCloseModal = () => {
    dispatch(hidePopUp());
  };

  const confirmAction = () => {
    context.buttonAction?.();
    handleCloseModal();
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      className="w-96"
      contentLabel={context.title || ''}
      onRequestClose={handleCloseModal}
    >
      <p className="mb-6 break-words">{context.message}</p>
      <div className="text-center">
        <button className="btn btn-primary font-bold" onClick={confirmAction}>
          {context.buttonText}
        </button>
      </div>
    </ModalDialog>
  );
};

export default ConfirmActionModal;
