enum SiteEnvironmentEnum {
  Local = 'local',
  Docker = 'docker',
  Staging = 'staging',
  Prod = 'prod',
}

const CDN_URLS = {
  [SiteEnvironmentEnum.Staging]: 'd2mwnmk50ijuf9',
  [SiteEnvironmentEnum.Prod]: 'd3g7htsbjjywiv',
};

/**
 * ES6 module imports are hoisted, so rather than declaring/updating this
 * variable within commonAssets.ts / an entry point, it needs its own module
 * https://github.com/webpack/webpack/issues/2776#issuecomment-233208623
 */
const hostName = Object.keys(CDN_URLS).includes(__SITE_ENV__)
  ? `${CDN_URLS[__SITE_ENV__]}.cloudfront.net`
  : window.location.hostname;

__webpack_public_path__ = `https://${hostName}/assets/build/`;
