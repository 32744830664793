import './commonAssets';

// TODO: cleanup. Most of these are included in the appropriate AssetConfigs and unnecessary here
import '../common/javascripts/modules/member/MemberSubMembers.js';
import '../common/javascripts/modules/signup/JoinStep1BMT.js';
import '../common/javascripts/modules/survey/SurveyRedirect.js';

import '../common/stylesheets/maintenance.css';
import '../common/stylesheets/modules/collections/collection-browse.less';
import '../common/stylesheets/modules/collections/collection-template.less';
import '../common/stylesheets/modules/details-uff.less';
import '../common/stylesheets/modules/widgets/testimonials.less';
import '../common/stylesheets/modules/howItWorks/all-media-types-info.less';
import '../common/stylesheets/modules/notfound/not-found.less';
import '../common/stylesheets/modules/popups/paymentMethodInfo.less';
import '../common/stylesheets/modules/signup/bmt-free-trial.less';
import '../common/stylesheets/modules/signup/user-lead-form-drop-shadow.less';
import '../common/stylesheets/modules/survey/survey.less';
import '../common/stylesheets/modules/widgets/full-width-background-widget.less';
import '../common/stylesheets/modules/widgets/media-grid-container.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget-card-design-v2.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget-card-design.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget.less';
import '../videoblocks/modules/stock/collections/restSpinnerOverlay.less';
import '../videoblocks/stylesheets/components/mixins.less';
import '../videoblocks/stylesheets/modules/details-uff.less';
import '../videoblocks/stylesheets/modules/homepage/homepage.less';
import '../common/stylesheets/modules/hero/hero.less';
import '../videoblocks/stylesheets/modules/search/tabbed-search.less';
import '../react/builder-io/builder-io.less'


import AppLoader from '../react/AppLoader/AppLoader';

import PopUpsContainer from '../react/PopUps/PopUpsContainer';
import ReactToastifyContainer from '../react/ReactToastify/containers/ReactToastifyContainer';
import PageNotFound from '../react/common/components/PageNotFound';
import NPSModalLoaderContainer from '../react/NPSModalLoader/NPSModalLoaderContainer';
import GenericFAQ from '../react/common/components/GenericFAQ';
import BuilderClientSideRenderer from '../react/builder-io/BuilderClientSideRenderer';
import EntTrialBanner from "../react/Trials/EntTrialBanner";
import NavApps from "../react/app/NavApps";

// React entry point

AppLoader.load({
  routes: [
    {
      name: 'PageNotFound',
      path: '*',
      component: PageNotFound,
    },
  ],
  auxApps: {
    ...NavApps,
    'pop-up-container': PopUpsContainer, // FIXME: causes missing matching <div> in <section>
    'nps-modal': NPSModalLoaderContainer,
    'react-toastify-container': ReactToastifyContainer, // FIXME: causes missing matching <div> in <section>
    'builder-io-wrapper': BuilderClientSideRenderer,
  },
  propBasedApps: {
    genericFrequentlyAskedQuestions: GenericFAQ,
    'entTrialBanner': EntTrialBanner
  },
});
