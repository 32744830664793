import React, { PureComponent } from 'react';
import { twMerge } from 'tailwind-merge';

import SiteConstants from '../SiteConstants/SiteConstants';

import '../../../common/stylesheets/modules/widgets/generic-faq.less';

interface Props {
  sections: Section[];
  showLearnMore: boolean;
  title: string;
}

interface State {
  openSection: string;
}

interface Section {
  sectionName: string;
  title: string;
  text: string;
  class: string;
}

class GenericFAQ extends PureComponent<Props, State> {
  static defaultProps = {
    title: 'Frequently Asked Questions',
    sections: [],
    showLearnMore: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      openSection: '',
    };
  }

  render() {
    return (
      <div className="faqContainer">
        <h3 className="faqContainer-header font-serif">{this.props.title}</h3>
        <ul className="faqContainer-list">
          {this.props.sections.map((section) => {
            const { sectionName, title, text } = section;
            const isOpen = this.isAnOpenSection(sectionName);
            return (
              <li
                key={`faqItem-${sectionName}`}
                id={`faqItem-${sectionName}`}
                className={twMerge(
                  'faqContainer-list-faqItem',
                  isOpen && 'isOpen',
                  section.class && section.class
                )}
                onClickCapture={() => this.toggleSection(sectionName)}
              >
                <div className="faqItem-header">
                  <h5 className="faqItem-header-title">{title}</h5>
                  <img
                    src="/assets/common/images/chevron.svg"
                    alt="expand"
                    width="24"
                    height="24"
                    className="expander-icon"
                  />
                </div>
                <p
                  className="faqItem-text"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </li>
            );
          })}
        </ul>
        {this.props.showLearnMore ? this.renderLearnMoreLink() : null}
      </div>
    );
  }

  renderLearnMoreLink() {
    return (
      <a
        href={SiteConstants.getIntercomHelpCenterUrl()}
        className="faqContainer-learnMoreLink"
        target="_blank"
      >
        More FAQs &#8594;
      </a>
    );
  }

  toggleSection = (sectionName) => {
    this.setState({
      openSection: this.isAnOpenSection(sectionName) ? '' : sectionName,
    });
  };

  isAnOpenSection(sectionName) {
    const { openSection } = this.state;
    return openSection === sectionName;
  }
}

export default GenericFAQ;
