import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchAllFolders } from '../Member/MemberBins/actions/FoldersActions';
import { FOLDERS_STORAGE_KEY } from '../Member/MemberBins/middleware/persistFoldersMiddleware';
import { selectAuthPermissions } from '../auth/AuthSelectors';
import ConfirmActionModal from './components/ConfirmActionModal';
import EnterpriseAccountManagerModal from './components/EnterpriseAccountManagerModal';

type Props = {
  isEnterpriseMember: boolean;
  hasFolderAccess: boolean;
  fetchAllFolders: typeof fetchAllFolders;
};

class PopUpsContainer extends Component<Props> {
  componentDidMount() {
    if (this.props.hasFolderAccess) {
      this.props.fetchAllFolders();
    } else if (window?.localStorage) {
      localStorage.removeItem(FOLDERS_STORAGE_KEY);
    }
  }

  render() {
    return (
      <div id="pop-up-container">
        {this.props.isEnterpriseMember && <EnterpriseAccountManagerModal />}
        <ConfirmActionModal />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const isEnterpriseMember =
    state.details?.features?.isEnterpriseMember ||
    state.search?.features?.isEnterpriseMember;
  const { hasFolderAccess } = selectAuthPermissions(state);

  return {
    isEnterpriseMember,
    hasFolderAccess,
  };
}

export default connect(mapStateToProps, {
  fetchAllFolders,
  // @ts-expect-error expect ts error
})(PopUpsContainer);
