import SiteMessageWidget from '../../widgets/SiteMessageWidget';

var MemberSubMembers = function (args) {
  this.tableWrapper = args.submember_table_wrapper;
  this.id = args.param_id;

  this.subMemberCancelClass = args.submember_cancel_class;
  this.subMemberCancelRoute = args.submember_cancel_route;
  this.subMemberCancelConfirmationMessage =
    args.submember_cancel_confirmation_message;

  this.subMemberCancelInviteClass = args.submember_cancel_invite_class;
  this.subMemberCancelInviteRoute = args.submember_cancel_invite_route;
  this.subMemberCancelInviteConfirmationMessage =
    args.submember_cancel_invite_confirmation_message;

  this.subMemberReactivateClass = args.submember_reactivate_class;
  this.subMemberReactivateRoute = args.submember_reactivate_route;

  this.subMemberReInviteClass = args.submember_reinvite_class;
  this.subMemberReInviteRoute = args.submember_reinvite_route;
  this.subMemberReInviteConfirmationMessage =
    args.submember_reinvite_confirmation_message;

  this.responsePlaceHolderId = '#' + args.response_placeholder;

  var self = this;

  $(document).ready(function () {
    var $subMemberTable = $('#' + self.tableWrapper);

    $subMemberTable.delegate(
      '.' + self.subMemberCancelClass,
      'click',
      $.proxy(self.cancelSubMember, self)
    );
    $subMemberTable.delegate(
      '.' + self.subMemberCancelInviteClass,
      'click',
      $.proxy(self.cancelSubMemberInvite, self)
    );
    $subMemberTable.delegate(
      '.' + self.subMemberReactivateClass,
      'click',
      $.proxy(self.subMemberReactivate, self)
    );
    $subMemberTable.delegate(
      '.' + self.subMemberReInviteClass,
      'click',
      $.proxy(self.subMemberReInvite, self)
    );
  });
};

MemberSubMembers.prototype.cancelSubMember = function (e) {
  var vm = this;

  swal(
    {
      title: 'Are you sure?',
      text: vm.subMemberCancelConfirmationMessage,
      type: 'warning',
      showCancelButton: true,
    },
    function () {
      var uid = $(e.currentTarget).data(vm.id);

      $.ajax({
        url: vm.subMemberCancelRoute,
        type: 'POST',
        data: {
          id: uid,
        },
        success: vm.updateMessage,
        dataType: 'json',
        error: vm.subMemberFunctionError,
        context: vm,
      });
    }
  );
};

MemberSubMembers.prototype.cancelSubMemberInvite = function (e) {
  var vm = this;

  swal(
    {
      title: 'Are you sure?',
      text: vm.subMemberCancelInviteConfirmationMessage,
      type: 'warning',
      showCancelButton: true,
    },
    function () {
      var uid = $(e.currentTarget).data(vm.id);

      $.ajax({
        url: vm.subMemberCancelInviteRoute,
        type: 'POST',
        data: {
          id: uid,
        },
        success: vm.updateMessage,
        dataType: 'json',
        error: vm.subMemberFunctionError,
        context: vm,
      });
    }
  );
};

MemberSubMembers.prototype.subMemberReactivate = function (e) {
  var vm = this;

  swal(
    {
      title: 'Are you sure?',
      text: vm.subMemberReactivateConfirmationmessage,
      type: 'warning',
      showCancelButton: true,
    },
    function () {
      var uid = $(e.currentTarget).data(vm.id);

      $.ajax({
        url: vm.subMemberReactivateRoute,
        type: 'POST',
        data: {
          id: uid,
        },
        success: vm.updateMessage,
        dataType: 'json',
        error: vm.subMemberFunctionError,
        context: vm,
      });
    }
  );
};

MemberSubMembers.prototype.subMemberReInvite = function (e) {
  var vm = this;

  swal(
    {
      title: 'Are you sure?',
      text: vm.subMemberReInviteConfirmationMessage,
      type: 'warning',
      showCancelButton: true,
    },
    function () {
      var uid = $(e.currentTarget).data(vm.id);

      $.ajax({
        url: vm.subMemberReInviteRoute,
        type: 'POST',
        data: {
          id: uid,
        },
        success: vm.updateMessage,
        dataType: 'json',
        error: vm.subMemberFunctionError,
        context: vm,
      });
    }
  );
};

MemberSubMembers.prototype.updateMessage = function (response) {
  var type = response.success
    ? SiteMessageWidget.TYPE.SUCCESS
    : SiteMessageWidget.TYPE.DANGER;
  var messageWidget = new SiteMessageWidget(response.message, type);

  var args = {};
  args.autoDismiss = false;
  messageWidget.Render(this.responsePlaceHolderId, args);

  if (response.success && response.info) {
    var element = response.info.unique_id;
    $('#' + element)
      .closest('tr')
      .fadeOut('normal', function () {
        $(this).remove();
      });
  }
};

MemberSubMembers.prototype.subMemberFunctionError = function () {
  var messageWidget = new SiteMessageWidget(
    'An internal error has occurred. Please try again.',
    SiteMessageWidget.TYPE.DANGER
  );
  messageWidget.Render(this.responsePlaceHolderId);
};

window.MemberSubMembers = MemberSubMembers; // Exposes for SiteJavascriptWidgetView
window.dispatchEvent(new Event('MemberSubMembers')); // Signals that definition is ready for use
