/**
 *
 * @param message - Message to display
 * @param type - type of message; see constants below for options
 * @param autoDismiss - whether or not the message should automatically be dismissed in a certain amount of time
 * @param autoDismissDelay - delay after which message is automatically dismissed
 * @constructor
 */
var SiteMessageWidget = function (message, type) {
  this.message = message;
  this.type = type;
};

SiteMessageWidget.TYPE = {};
SiteMessageWidget.TYPE.SUCCESS = 'alert-success';
SiteMessageWidget.TYPE.INFO = 'alert-info';
SiteMessageWidget.TYPE.WARNING = 'alert-warning';
SiteMessageWidget.TYPE.DANGER = 'alert-danger';

/**
 *
 * @param selector - the message widget will be appended to $(selector)
 * @param args - object with the following params:
 *              {
 *                    fadeIn : bool - whether or not it should fadeIn (false --> instant display)
                    , fadeInTime : int - length of fade in animation in milliseconds

                    , autoDismiss : bool - whether or not to automatically dismiss the message
                    , autoDismissDelay : int - length of time before the message is dismissed in milliseconds

                    , fadeOut : bool - if true, the message fades out when auto-dismissed
                    , fadeOutTime : int - length of fade out animation in milliseconds
 *              }
 *
 * @constructor
 */
SiteMessageWidget.prototype.Render = function (selector, args) {
  args = args || {};

  var autoDismiss = args.autoDismiss || false;
  var autoDismissDelay = args.autoDismissDelay || 3000;
  var fadeIn = args.fadeIn || false;
  var fadeInTime = args.fadeInTime || 1000;
  var fadeOut = args.fadeOut || false;
  var fadeOutTime = args.fadeOutTime || 1000;

  // Create the actual message HTML
  this.$messageHtml = $('<div class="alert ' + this.type + '"></div>');
  this.$messageHtml.append(
    '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>'
  );
  this.$messageHtml.append(this.message);

  $(selector).html('');

  // If the message is fading in, then it needs to start off hidden and be faded in
  if (fadeIn) {
    this.$messageHtml.hide().appendTo(selector).fadeIn(fadeInTime);
  } else {
    this.$messageHtml.appendTo(selector);
  }

  if (autoDismiss) {
    setTimeout(
      $.proxy(function () {
        this.Dismiss(fadeOut, fadeOutTime);
      }, this),
      autoDismissDelay
    );
  }

  if (this.autoDismiss) {
    setTimeout(
      $.proxy(function () {
        selectorElement.find('.close').trigger('click');
      }, this),
      this.autoDismissDelay
    );
  }
};

SiteMessageWidget.prototype.Dismiss = function (fadeOut, fadeOutTime) {
  this.$messageHtml.fadeOut(
    fadeOutTime,
    $.proxy(function () {
      this.$messageHtml.find('.close').trigger('click');
    }, this)
  );
};

export default SiteMessageWidget;
