import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalDialog from '@videoblocks/shared-components/dist/mjs/components/Shared/ModalDialog';

import SiteConstants from '../../common/SiteConstants/SiteConstants';
import { hidePopUp } from '../actions/PopUpsActions';
import {
  selectPopUpIsOpen,
  selectPopUpsContext,
} from '../selectors/PopUpsSelectors';
import { ENTERPRISE_ACCOUNT_MANAGER_MODAL } from './types';

const EnterpriseAccountManagerModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    selectPopUpIsOpen(ENTERPRISE_ACCOUNT_MANAGER_MODAL)
  );
  const context = useSelector(selectPopUpsContext);

  const handleCloseModal = () => {
    dispatch(hidePopUp());
  };

  const content = () => {
    switch (context.contactReason) {
      case 'library_not_allowed':
        const emailAddress =
          SiteConstants.getInstance().getEnterpriseAccountManagerEmail();
        const emailLink = <a href={`mailto:${emailAddress}`}>{emailAddress}</a>;
        return (
          <p>
            Your Storyblocks subscription does not grant access to the selected
            item.
            <br />
            Please contact your account manager at {emailLink} to discuss
            upgrade options.
          </p>
        );
      default:
        return (
          <p>There has been an error. Please contact your account manager.</p>
        );
    }
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      contentLabel="Contact Your Account Manager"
      onRequestClose={handleCloseModal}
    >
      {content()}
    </ModalDialog>
  );
};

export default EnterpriseAccountManagerModal;
